import React, { useState, useEffect } from 'react';
//import AOS from 'aos';
//import 'aos/dist/aos.css';
import './Galeria.scss';

const Galeria = () => {
    const [selectedImage, setSelectedImage] = useState(null);

    useEffect(() => {
        //AOS.init({ duration: 2000 });
    }, []);

    const galleryItems = [
        { link: "#", imgSrc: "graphic/a.jpg" },
        { link: "#", imgSrc: "graphic/b.jpg" },
        { link: "#", imgSrc: "graphic/c.jpg" },
        { link: "#", imgSrc: "graphic/d.jpg" },
        { link: "#", imgSrc: "graphic/e.jpg" },
        { link: "#", imgSrc: "graphic/f.jpg"},
        { link: "#", imgSrc: "graphic/g.jpg"},
        { link: "#", imgSrc: "graphic/h.jpg"},
        { link: "#", imgSrc: "graphic/i.jpg"},
        { link: "#", imgSrc: "graphic/j.jpg"},
        { link: "#", imgSrc: "graphic/k.jpg" },
        { link: "#", imgSrc: "graphic/l.jpg"},
        { link: "#", imgSrc: "graphic/m.jpg" },
        { link: "#", imgSrc: "graphic/n.jpg" },
        { link: "#", imgSrc: "graphic/u.jpg"},
        { link: "#", imgSrc: "graphic/w.jpg" },
        { link: "#", imgSrc: "graphic/x.jpg" },
        { link: "#", imgSrc: "graphic/y.jpg" },
        { link: "#", imgSrc: "graphic/z.jpg" },
        { link: "#", imgSrc: "graphic/aa.jpg"},
        { link: "#", imgSrc: "graphic/bb.jpg"},
        { link: "#", imgSrc: "graphic/cc.jpg"},
    ];

    const handleImageClick = (imgSrc) => {
        setSelectedImage(imgSrc);
    };

    const handleCloseModal = () => {
        setSelectedImage(null);
    };

    return (
        <div>
            <div className="gallery">
                {galleryItems.map((item, index) => (
                    <div
                        className="gallery__link"
                        key={index}
                        onClick={() => handleImageClick(item.imgSrc)}
                        data-aos="fade-up"
                    >
                        <figure className="gallery__thumb">
                            <img src={item.imgSrc} alt={item.alt} className="gallery__image" />
                            <figcaption className="gallery__caption">{item.caption}</figcaption>
                        </figure>
                    </div>
                ))}
            </div>

            {selectedImage && (
                <div className="modal" onClick={handleCloseModal}>
                    <span className="modal__close">&times;</span>
                    <img className="modal__content" src={selectedImage} alt="Expanded view" />
                </div>
            )}
        </div>
    );
};

export default Galeria;

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // Dodano useNavigate
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {
    FaPhone,
    FaEnvelope,
    FaChevronLeft,
    FaChevronRight,
} from 'react-icons/fa';

const Gallery = ({ images }) => {
    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                overflow: 'hidden',
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                userSelect: 'none',
            }}
        >
            <Carousel
                autoPlay
                infiniteLoop
                showThumbs={false}
                showStatus={false}
                interval={4000}
                showArrows={true}
                selectedItem={0}
                emulateTouch
                transitionTime={500}
                swipeable
                useKeyboardArrows
                stopOnHover
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (
                        <button
                            type="button"
                            onClick={onClickHandler}
                            title={label}
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: '10px',
                                zIndex: 2,
                                backgroundColor: 'transparent',
                                color: '#ffffff',
                                border: 'none',
                                padding: '15px',
                                cursor: 'pointer',
                                transform: 'translateY(-50%)',
                                borderRadius: '50%',
                                boxShadow: 'none',
                                transition:
                                    'background-color 0.3s ease, transform 0.3s ease',
                            }}
                            onMouseEnter={(e) => {
                                e.target.style.backgroundColor = '#ff7e6b';
                                e.target.style.boxShadow =
                                    '0 4px 8px rgba(0, 0, 0, 0.2)';
                                e.target.style.transform =
                                    'translateY(-50%) scale(1.2)';
                            }}
                            onMouseLeave={(e) => {
                                e.target.style.backgroundColor = 'transparent';
                                e.target.style.boxShadow = 'none';
                                e.target.style.transform =
                                    'translateY(-50%) scale(1)';
                            }}
                        >
                            <FaChevronLeft size={24} />
                        </button>
                    )
                }
                renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && (
                        <button
                            type="button"
                            onClick={onClickHandler}
                            title={label}
                            style={{
                                position: 'absolute',
                                top: '50%',
                                right: '10px',
                                zIndex: 2,
                                backgroundColor: 'transparent',
                                color: '#ffffff',
                                border: 'none',
                                padding: '15px',
                                cursor: 'pointer',
                                transform: 'translateY(-50%)',
                                borderRadius: '50%',
                                boxShadow: 'none',
                                transition:
                                    'background-color 0.3s ease, transform 0.3s ease',
                            }}
                            onMouseEnter={(e) => {
                                e.target.style.backgroundColor = '#ff7e6b';
                                e.target.style.boxShadow =
                                    '0 4px 8px rgba(0, 0, 0, 0.2)';
                                e.target.style.transform =
                                    'translateY(-50%) scale(1.2)';
                            }}
                            onMouseLeave={(e) => {
                                e.target.style.backgroundColor = 'transparent';
                                e.target.style.boxShadow = 'none';
                                e.target.style.transform =
                                    'translateY(-50%) scale(1)';
                            }}
                        >
                            <FaChevronRight size={24} />
                        </button>
                    )
                }
                renderIndicator={(onClickHandler, isSelected, index, label) => (
                    <li
                        style={{
                            border: '2px solid #e94e1b',
                            borderRadius: '50%',
                            width: '14px',
                            height: '14px',
                            display: 'inline-block',
                            margin: '0 6px',
                            cursor: 'pointer',
                            backgroundColor: isSelected ? '#e94e1b' : '#fff',
                        }}
                        key={index}
                        onClick={onClickHandler}
                        onKeyDown={onClickHandler}
                        value={index}
                        role="button"
                        tabIndex={0}
                        aria-label={`${label} ${index + 1}`}
                        title={`${label} ${index + 1}`}
                    />
                )}
            >
                {images.map((image, index) => (
                    <div
                        key={index}
                        style={{
                            width: '100%',
                            height: '100%',
                            overflow: 'hidden',
                            position: 'relative',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            userSelect: 'none',
                        }}
                    >
                        <img
                            src={image}
                            alt={`Gallery image ${index + 1}`}
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                userSelect: 'none',
                                maxWidth: '100vw',
                                maxHeight: '100vh',
                                pointerEvents: 'none',
                            }}
                        />
                    </div>
                ))}
            </Carousel>
        </div>
    );
};

const ContactSection = () => {
    return (
        <div
            id="KONTAKT" // Dodany id dla przewijania
            style={{
                width: '100%',
                padding: '40px 20px',
                backgroundImage: 'url(/graphic/contact.jpg)',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: '#ffffff',
                userSelect: 'none',
            }}
        >
            <h2
                style={{
                    fontSize: '2.5em',
                    fontWeight: 'bold',
                    marginBottom: '20px',
                }}
            >
                KONTAKT
            </h2>
            <p
                style={{
                    fontSize: '1.2em',
                    lineHeight: '1.6',
                    maxWidth: '800px',
                    margin: '20px 0',
                }}
            >
                Jeżeli chcą Państwo dokonać rezerwacji lub uzyskać więcej
                informacji zapraszamy do kontaktu
            </p>
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    gap: '20px',
                    marginTop: '30px',
                }}
            >
                <div
                    style={{
                        backgroundColor: '#ffffff',
                        padding: '30px',
                        borderRadius: '8px',
                        width: '300px',
                        textAlign: 'center',
                        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
                        transition:
                            'transform 0.3s ease, box-shadow 0.3s ease',
                        userSelect: 'none',
                    }}
                    onMouseEnter={(e) => {
                        e.currentTarget.style.transform = 'translateY(-10px)';
                        e.currentTarget.style.boxShadow =
                            '0 16px 32px rgba(0, 0, 0, 0.2)';
                    }}
                    onMouseLeave={(e) => {
                        e.currentTarget.style.transform = 'translateY(0)';
                        e.currentTarget.style.boxShadow =
                            '0 8px 16px rgba(0, 0, 0, 0.1)';
                    }}
                >
                    <div
                        style={{
                            backgroundColor: '#ff5e50',
                            borderRadius: '50%',
                            width: '60px',
                            height: '60px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            margin: '-50px auto 20px',
                            pointerEvents: 'none',
                        }}
                    >
                        <FaPhone
                            style={{
                                color: '#ffffff',
                                fontSize: '24px',
                                transform: 'rotate(90deg)',
                            }}
                        />
                    </div>
                    <h3
                        style={{
                            fontSize: '1.6em',
                            fontWeight: '600',
                            marginBottom: '15px',
                        }}
                    >
                        Telefon
                    </h3>
                    <p
                        style={{
                            fontSize: '1.1em',
                            color: '#333',
                            marginBottom: '8px',
                        }}
                    >
                        +48 52 30 777 55
                    </p>
                </div>
                <div
                    style={{
                        backgroundColor: '#ffffff',
                        padding: '30px',
                        borderRadius: '8px',
                        width: '300px',
                        textAlign: 'center',
                        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
                        transition:
                            'transform 0.3s ease, box-shadow 0.3s ease',
                        userSelect: 'none',
                    }}
                    onMouseEnter={(e) => {
                        e.currentTarget.style.transform = 'translateY(-10px)';
                        e.currentTarget.style.boxShadow =
                            '0 16px 32px rgba(0, 0, 0, 0.2)';
                    }}
                    onMouseLeave={(e) => {
                        e.currentTarget.style.transform = 'translateY(0)';
                        e.currentTarget.style.boxShadow =
                            '0 8px 16px rgba(0, 0, 0, 0.1)';
                    }}
                >
                    <div
                        style={{
                            backgroundColor: '#ff5e50',
                            borderRadius: '50%',
                            width: '60px',
                            height: '60px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            margin: '-50px auto 20px',
                            pointerEvents: 'none',
                        }}
                    >
                        <FaEnvelope
                            style={{ color: '#ffffff', fontSize: '24px' }}
                        />
                    </div>
                    <h3
                        style={{
                            fontSize: '1.6em',
                            fontWeight: '600',
                            marginBottom: '15px',
                        }}
                    >
                        Email
                    </h3>
                    <p
                        style={{
                            fontSize: '0.9em',
                            color: '#333',
                            marginBottom: '8px',
                        }}
                    >
                        kontakt@nierownopodsufitem.pl
                    </p>

                </div>
            </div>
        </div>
    );
};

const MainPage = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const location = useLocation();
    const navigate = useNavigate(); // Dodano useNavigate

    // Funkcja do przewijania do sekcji na podstawie ID
    const scrollToSection = (sectionId) => {
        const element = document.getElementById(sectionId);
        if (element) {
            // Przewijanie do widoku
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });

            // Dostosowanie do wysokości nagłówka
            const headerHeight = document.querySelector('header').offsetHeight;
            const elementPosition =
                element.getBoundingClientRect().top + window.scrollY;
            const offsetPosition = elementPosition - headerHeight - 20;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth',
            });
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        // Sprawdzenie, czy w URL jest hash i przewinięcie do sekcji
        if (location.hash) {
            const sectionId = location.hash.replace('#', '');
            scrollToSection(sectionId);
        }

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [location]);

    // Funkcja obsługująca kliknięcie przycisku "Menu"
    const handleMenuClick = () => {
        navigate('/menu'); // Przekierowanie do strony menu
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '0',
                maxWidth: '100%',
                margin: '0 auto',
                fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
                backgroundColor: '#f7f7f7',
                position: 'relative',
                overflow: 'hidden',
                userSelect: 'none',
            }}
        >
            {isMobile ? (
                <>
                    <div
                        style={{
                            width: '100%',
                            padding: '20px',
                            backgroundImage: 'url(/graphic/top-left-bg.jpg)',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            color: '#ffffff',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                        }}
                    >
                        <img
                            src="/graphic/slogan.png"
                            alt="Logo"
                            style={{
                                width: '80%',
                                height: 'auto',
                                marginBottom: '20px',
                                userSelect: 'none',
                                pointerEvents: 'none',
                            }}
                        />
                        <button
                            style={{
                                marginTop: '30px',
                                padding: '20px 40px',
                                fontSize: '1.4em',
                                backgroundColor: '#f7f7f7',
                                color: '#e94e1b',
                                border: '2px solid #e94e1b',
                                borderRadius: '50px',
                                boxShadow: '0 12px 20px rgba(0, 0, 0, 0.2)',
                                cursor: 'pointer',
                                transition: 'all 0.3s ease',
                                fontWeight: 'bold',
                            }}
                            onMouseEnter={(e) => {
                                e.target.style.backgroundColor = '#e94e1b';
                                e.target.style.color = '#ffffff';
                                e.target.style.boxShadow =
                                    '0 15px 25px rgba(233, 78, 27, 0.4)';
                                e.target.style.transform = 'scale(1.1)';
                            }}
                            onMouseLeave={(e) => {
                                e.target.style.backgroundColor = '#ffffff';
                                e.target.style.color = '#e94e1b';
                                e.target.style.boxShadow =
                                    '0 12px 20px rgba(0, 0, 0, 0.2)';
                                e.target.style.transform = 'scale(1)';
                            }}
                            onClick={handleMenuClick} // Dodano obsługę kliknięcia
                        >
                            Menu
                        </button>
                    </div>
                    <div
                        style={{ width: '100%', padding: '0', marginTop: '0' }}
                    >
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                maxHeight: '100%',
                                overflow: 'hidden',
                            }}
                        >
                            <Gallery
                                images={[
                                    '/graphic/1.jpg',
                                    '/graphic/2.jpg',
                                    '/graphic/3.jpg',
                                    '/graphic/4.jpg',
                                    '/graphic/5.jpg',
                                ]}
                            />
                        </div>
                    </div>
                </>
            ) : (
                <div
                    style={{
                        width: '100%',
                        height: '70vh',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexDirection: 'row',
                    }}
                >
                    <div
                        style={{
                            width: '40%',
                            height: '100%',
                            padding: '20px',
                            backgroundImage: 'url(/graphic/top-left-bg.jpg)',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            color: '#ffffff',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                        }}
                    >
                        <img
                            src="/graphic/slogan.png"
                            alt="Logo"
                            style={{
                                width: '60%',
                                height: 'auto',
                                marginBottom: '20px',
                                userSelect: 'none',
                                pointerEvents: 'none',
                            }}
                        />
                        <button
                            style={{
                                marginTop: '30px',
                                padding: '20px 40px',
                                fontSize: '1.4em',
                                backgroundColor: '#f7f7f7',
                                color: '#e94e1b',
                                border: '2px solid #e94e1b',
                                borderRadius: '50px',
                                boxShadow: '0 12px 20px rgba(0, 0, 0, 0.2)',
                                cursor: 'pointer',
                                transition: 'all 0.3s ease',
                                fontWeight: 'bold',
                            }}
                            onMouseEnter={(e) => {
                                e.target.style.backgroundColor = '#e94e1b';
                                e.target.style.color = '#ffffff';
                                e.target.style.boxShadow =
                                    '0 15px 25px rgba(233, 78, 27, 0.4)';
                                e.target.style.transform = 'scale(1.1)';
                            }}
                            onMouseLeave={(e) => {
                                e.target.style.backgroundColor = '#ffffff';
                                e.target.style.color = '#e94e1b';
                                e.target.style.boxShadow =
                                    '0 12px 20px rgba(0, 0, 0, 0.2)';
                                e.target.style.transform = 'scale(1)';
                            }}
                            onClick={handleMenuClick} // Dodano obsługę kliknięcia
                        >
                            Menu
                        </button>
                    </div>
                    <div
                        style={{
                            width: '60%',
                            height: '100%',
                            padding: '0',
                            overflow: 'hidden',
                        }}
                    >
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                maxHeight: '100%',
                                overflow: 'hidden',
                            }}
                        >
                            <Gallery
                                images={[
                                    '/graphic/1.jpg',
                                    '/graphic/2.jpg',
                                    '/graphic/3.jpg',
                                    '/graphic/4.jpg',
                                    '/graphic/5.jpg',
                                ]}
                            />
                        </div>
                    </div>
                </div>
            )}
            <div
                style={{
                    width: '100%',
                    padding: '20px',
                    backgroundColor: '#f7f7f7',
                    marginTop: '40px',
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <h2
                    style={{
                        fontSize: '3em',
                        color: '#000000',
                        marginBottom: '20px',
                        textTransform: 'uppercase',
                        letterSpacing: '2px',
                        fontWeight: 'bold',
                        position: 'relative',
                    }}
                >
                    O NAS
                </h2>
                <p
                    style={{
                        fontSize: '1.2em',
                        lineHeight: '1.6',
                        color: '#4a4a4a',
                        maxWidth: '800px',
                        margin: '10px 0',
                        textAlign: 'left',
                        textJustify: 'inter-word',
                    }}
                >
                    Restauracja 'Nierówno Pod Sufitem' to wyjątkowe miejsce
                    zlokalizowane przy jednej z głównych ulic Inowrocławia, w
                    sercu miasta. Mieścimy się w budynku z 1908 roku, którego
                    wnętrza zdobią oryginalnie zachowane cegły z czasów I wojny
                    światowej, tworząc niepowtarzalny klimat. Subtelne
                    fotografie przedstawiające dawne obrazy miasta oraz
                    wyszukane dodatki budują ciepłą, przyjazną i rodzinną
                    atmosferę — idealną do delektowania się naszymi potrawami.
                </p>
                <p
                    style={{
                        fontSize: '1.2em',
                        lineHeight: '1.6',
                        color: '#4a4a4a',
                        maxWidth: '800px',
                        margin: '10px 0',
                        textAlign: 'left',
                        textJustify: 'inter-word',
                    }}
                >
                    W naszym menu znajdą Państwo zarówno klasyczne dania kuchni
                    polskiej, jak i autorskie propozycje naszych szefów kuchni.
                    Jesteśmy doskonałym miejscem na spotkania towarzyskie,
                    biznesowe oraz organizację bankietów i przyjęć
                    okolicznościowych. W 'Nierówno Pod Sufitem' stawiamy na
                    najwyższą jakość składników i wyjątkową obsługę, aby każde
                    Państwa spotkanie stało się niezapomnianym przeżyciem.
                    Oferujemy także usługi cateringowe oraz kompleksową obsługę
                    gastronomiczną dla wszelkiego rodzaju uroczystości.
                </p>
            </div>

            {/* Sekcja kontaktu */}
            <ContactSection />

            {/* Mapa */}
            <div
                style={{
                    width: '100%',
                    height: '35vh',
                    marginTop: '0',
                }}
            >
                <iframe
                    title="Google Map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2440.0415861724614!2d18.2506952!3d52.8007149!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4703508832b4e07b%3A0xdf3db3e0f9ef306!2sNier%C3%B3wno%20Pod%20Sufitem!5e0!3m2!1sen!2spl!4v1697043940535!5m2!1sen!2spl"
                    width="100%"
                    height="100%"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
            </div>
        </div>
    );
};

export default MainPage;
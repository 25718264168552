import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer-container">
            <div className="footer-content">
                <div className="footer-logo">
                    <img src="/graphic/logo.png" alt="Logo" />
                </div>
                <div className="footer-info">
                    <div className="footer-opening-hours">
                        <h3>Godziny Otwarcia</h3>
                        <p>Poniedziałek – Piątek: 11:00 – 16:00</p>
                        <p>Sobota - Niedziela: imprezy, cateringi</p>
                    </div>
                    <div className="footer-contact">
                        <h3>Kontakt</h3>
                        <p>Telefon: +48 52 30 777 55</p>
                        <p>E-mail: kontakt@nierownopodsufitem.pl</p>
                        <p>Dworcowa 31<br />88-110 Inowrocław</p>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <p>© 2024 Nierówno Pod Sufitem .All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import MainPage from './pages/MainPage';
import Menu from './pages/Menu';
import Galeria from './pages/Galeria';
import Header from './components/Header';
import Footer from './components/Footer';

function App() {
    return (
        <Router>
            <div className="App">
                <Header />
                <Routes>
                    <Route path="/" element={<MainPage />} />
                    <Route path="/menu" element={<Menu />} />
                    <Route path="/galeria" element={<Galeria />} />
                </Routes>
                <Footer />
            </div>
        </Router>
    );
}

export default App;

import React, { useState } from 'react';
import './Menu.css';

const MenuItem = ({ image, title, description, price }) => {
    return (
        <div className="menu-item" data-aos="fade-up">
            <div className="menu-item-image">
                <img src={image} alt={title} />
            </div>
            <div className="menu-item-details">
                <h3>{title}</h3>
                <p>{description}</p>
            </div>
            <div className="menu-item-price">
                <span>${price}</span>
            </div>
        </div>
    );
};

const Menu = () => {
    const [activeCategory, setActiveCategory] = useState('breakfast');

    const menuItems = {
        breakfast: [
            {
                image: '/graphic/eggs-omelette.jpg',
                title: 'Eggs Omelette',
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.',
                price: '14',
            },
            // ... pozostałe pozycje śniadaniowe
        ],
        // ... pozycje lunchowe i obiadowe
    };

    return (
        <div className="menu-section">
            <div className="menu-content">
                <h2 className="menu-section-title" data-aos="fade-up">NASZE MENU</h2>
                <div className="menu-categories" data-aos="fade-up">
                    {['breakfast', 'lunch', 'dinner'].map((category) => (
                        <button
                            key={category}
                            className={`menu-category ${activeCategory === category ? 'active' : ''}`}
                            onClick={() => setActiveCategory(category)}
                        >
                            {category.charAt(0).toUpperCase() + category.slice(1)}
                        </button>
                    ))}
                </div>
                <div className="menu-items active">
                    {menuItems[activeCategory].map((item, index) => (
                        <MenuItem
                            key={index}
                            image={item.image}
                            title={item.title}
                            description={item.description}
                            price={item.price}
                        />
                    ))}
                </div>
            </div>
            <div className="menu-overlay">
                <p>Nowe menu będzie dostępne w styczniu 2025</p>
            </div>
        </div>
    );
};

export default Menu;

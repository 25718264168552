import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet'; // Import Helmet for SEO
import './Header.css';
import { FaFacebookF } from 'react-icons/fa';

const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isScrolled, setIsScrolled] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const closeMenu = () => {
        setMenuOpen(false);
    };

    const handleResize = () => {
        if (window.innerWidth > 768) {
            setMenuOpen(false);
        }
        setIsMobile(window.innerWidth <= 768);
    };

    const handleScroll = () => {
        if (window.scrollY > 50) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    const handleNavigationAndScroll = (sectionId) => {
        navigate('/#' + sectionId);
        if (isMobile) {
            closeMenu();
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <>
            {/* Helmet for SEO */}
            <Helmet>
                <title>Nierówno Pod Sufitem</title>
                <meta name="description" content="Nierówno Pod Sufiem - Inowrocław" />
                <meta name="keywords" content="Nierówno Pod Sufitem, Strona Główna, Menu, Kontakt, Galeria" />
                <link rel="canonical" href="https://nierownopodsufitem.pl//" />
            </Helmet>

            <header className={`header-container ${isScrolled ? 'scrolled' : ''}`}>
                <div className="logo-container">
                    <NavLink to="/" onClick={closeMenu}>
                        <img src="/graphic/logo.png" alt="Logo Twojej firmy" className="logo" />
                    </NavLink>
                    {!isMobile && (
                        <div className="header-info">
                            <span className="phone-number">+48 52 30 777 55</span>
                            <span className="separator">|</span>
                            <a href="https://www.facebook.com/profile.php?id=100063583718251&locale=pl_PL" target="_blank" rel="noopener noreferrer" className="facebook-icon">
                                <FaFacebookF />
                            </a>
                        </div>
                    )}
                    {isMobile && (
                        <button className="menu-toggle" onClick={toggleMenu} aria-label="Toggle navigation menu">
                            &#9776;
                        </button>
                    )}
                </div>
                <div className="divider"></div>
                <nav className={`nav-menu ${menuOpen ? 'expanded' : ''}`}>
                    <ul className={`nav-links ${menuOpen ? 'show' : 'hide'}`}>
                        <li>
                            <NavLink to="/" className={({ isActive }) => (isActive ? 'active' : '')} onClick={closeMenu}>
                                Strona główna
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/menu" className={({ isActive }) => (isActive ? 'active' : '')} onClick={closeMenu}>
                                Menu
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/galeria" className={({ isActive }) => (isActive ? 'active' : '')} onClick={closeMenu}>
                                Galeria
                            </NavLink>
                        </li>
                        <li>
                            <button onClick={() => handleNavigationAndScroll('KONTAKT')} className="nav-link-button">
                                Kontakt
                            </button>
                        </li>
                        {isMobile && (
                            <li className="mobile-header-info">
                                <div className="mobile-contact-info">
                                    <span className="phone-number">+48 600 000 000</span>
                                    <a href="https://www.facebook.com/profile.php?id=100063583718251&locale=pl_PL" target="_blank" rel="noopener noreferrer" className="facebook-icon">
                                        <FaFacebookF />
                                    </a>
                                </div>
                            </li>
                        )}
                    </ul>
                </nav>
            </header>
        </>
    );
};

export default Header;
